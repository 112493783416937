import * as React from "react";
import { useCallback } from "react";
import { navigate } from "gatsby";
import SubHeader1 from "../components/sub-header1";
import DownloadsSection from "../components/downloads-section";
import CTA3 from "../components/c-t-a";
import FAQ from "../components/f-a-q";
import styled from "styled-components";
import Footer1 from '../components/footer1';

const IdmLogo22Icon = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
const ImageLogo = styled.div`
  width: 10rem;
  height: 4.125rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const Address = styled.span`
  font-weight: 600;
`;
const OrdStreetWest = styled.span``;
const Address33OrdContainer = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  @media screen and (max-width: 420px) {
    text-align: center;
  }
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const Phone = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  font-weight: 600;
`;
const Content1 = styled.div`
  width: 54rem;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const IconFacebook = styled.img`
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
`;
const IconLinkedin = styled.img`
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const Content2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  @media screen and (max-width: 420px) {
    align-items: center;
    justify-content: flex-start;
  }
`;
const Column = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  @media screen and (max-width: 420px) {
    align-items: center;
    justify-content: flex-start;
  }
`;
const Contact = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
`;
const Faqs = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  cursor: pointer;
`;
const Blog = styled.div`
  width: 11.25rem;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  display: none;
`;
const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const Column1 = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem var(--padding-21xl);
  gap: var(--gap-51xl);
  font-size: var(--text-medium-light-size);
  @media screen and (max-width: 420px) {
    flex-direction: row;
    padding-left: 0rem;
    padding-top: var(--padding-21xl);
    padding-right: 0rem;
    box-sizing: border-box;
  }
`;
const Content3 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
`;
const Divider = styled.div`
  align-self: stretch;
  position: relative;
  background-color: var(--border-primary);
  border: 1px solid var(--text-alternate);
  box-sizing: border-box;
  height: 0.063rem;
`;
const IdmAllRights = styled.div`
  position: relative;
  line-height: 150%;
  @media screen and (max-width: 420px) {
    text-align: center;
  }
`;
const PrivacyPolicy = styled.div`
  position: relative;
  text-decoration: underline;
  line-height: 150%;
`;
const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const Row = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 420px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;
const Credits = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
`;
const Footer = styled.footer`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-45xl);
  gap: var(--gap-61xl);
  text-align: left;
  font-size: var(--text-medium-size);
  color: var(--text-alternate);
  font-family: var(--text-tiny-normal);
`;
const InvestorUpdatesRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: var(--primary-main);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const InvestorUpdates = () => {
  const onFAQsTextClick = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onHomeTextClick = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onAboutTextClick = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onProjectTextClick = useCallback(() => {
    navigate("/the-mankayan-project");
  }, []);

  const onInvestorsTextClick = useCallback(() => {
    navigate("/investor-updates");
  }, []);

  return (
    <InvestorUpdatesRoot>
      <SubHeader1 />
      <DownloadsSection />
      <CTA3 />
      <FAQ />
      <Footer1 /> 
    </InvestorUpdatesRoot>
  );
};

export default InvestorUpdates;
