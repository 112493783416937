import * as React from "react";
import { useCallback } from "react";
import { Button, Icon } from "@mui/material";
import { navigate } from "gatsby";
import styled from "styled-components";

const Subheading = styled.div`
  position: relative;
  line-height: 150%;
  font-weight: 300;
`;
const Heading = styled.h3`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  text-transform: uppercase;
  font-weight: 400;
  font-family: inherit;
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
  font-family: var(--text-regular-light);
  color: var(--text-alternate);
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  min-width: 37.5rem;
  text-align: center;
  font-size: var(--heading-desktop-h2-size);
  color: var(--primary-highlight);
  font-family: var(--heading-desktop-h4);
  @media screen and (max-width: 420px) {
    min-width: auto;
  }
`;
const SectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const PlaceholderImageIcon = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 18.75rem;
  flex-shrink: 0;
  object-fit: cover;
`;
const Text2 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-regular-light-size);
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--primary-highlight);
`;
const Heading1 = styled.div`
  width: 26rem;
  position: relative;
  line-height: 150%;
  font-weight: 300;
  display: none;
`;
const Text3 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  font-weight: 300;
`;
const Content1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const Cardcontent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const Button1 = styled(Button)``;
const Actions = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-base) 0rem 0rem;
`;
const Card = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  @media screen and (max-width: 420px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Heading2 = styled.div`
  width: 25.669rem;
  position: relative;
  line-height: 150%;
  font-weight: 300;
  display: none;
`;
const PlaceholderImageIcon1 = styled.img`
  width: 3rem;
  position: relative;
  height: 3rem;
  object-fit: cover;
`;
const Text4 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  font-weight: 600;
`;
const Text5 = styled.div`
  position: relative;
  line-height: 150%;
`;
const Div = styled.div`
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
`;
const Time = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const Content2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Avatar = styled.div`
  width: 25.669rem;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--text-small-normal-size);
  color: var(--border-primary);
`;
const Text6 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const Text7 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
  color: var(--text-alternate);
`;
const Cardcontent1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--text-regular-light-size);
  color: var(--primary-highlight);
`;
const Card1 = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  cursor: pointer;
  @media screen and (max-width: 420px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Content3 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
`;
const UpdatesRoot = styled.section`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  gap: var(--gap-61xl);
  text-align: left;
  font-size: var(--text-medium-light-size);
  color: var(--text-alternate);
  font-family: var(--text-regular-light);
  @media screen and (max-width: 420px) {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
`;

const Updates1 = () => {
  const onCardContainerClick = useCallback(() => {
    navigate("/investor-updates");
  }, []);

  const onCardContainer1Click = useCallback(() => {
    navigate("/investor-updates");
  }, []);

  const onCardContainer2Click = useCallback(() => {
    navigate("/investor-updates");
  }, []);

  return (
    <UpdatesRoot>
      <SectionTitle>
        <Subheading>News</Subheading>
        <Content>
          <Heading>Latest Project Updates</Heading>
          <Text1>Stay informed with the latest news and announcements.</Text1>
        </Content>
      </SectionTitle>
      <Content3>
        <Card onClick={onCardContainerClick}>
          <Cardcontent>
            <PlaceholderImageIcon alt="" src="/placeholder-image32@2x.png" />
            <Content1>
              <Text2>News and Announcements</Text2>
              <Heading1>News and Announcements</Heading1>
              <Text3>
                Stay informed with the latest updates and announcements.
              </Text3>
            </Content1>
          </Cardcontent>
          <Actions>
            <Button1
              disableElevation={true}
              color="primary"
              variant="text"
              endIcon={<Icon>arrow_forward_ios_sharp</Icon>}
              
            >
              Read More
            </Button1>
          </Actions>
        </Card>
        <Card onClick={onCardContainer1Click}>
          <Cardcontent>
            <PlaceholderImageIcon alt="" src="/placeholder-image33@2x.png" />
            <Content1>
              <Heading2>Results and Reports</Heading2>
              <Text2>Results and Reports</Text2>
              <Text3>
                Access detailed information and updates on operations and
                performance.
              </Text3>
            </Content1>
          </Cardcontent>
          <Avatar>
            <PlaceholderImageIcon1 alt="" src="/placeholder-image34@2x.png" />
            <Content2>
              <Text4>Jane Smith</Text4>
              <Time>
                <Text5>15 Feb 2022</Text5>
                <Div>•</Div>
                <Text5>7 min read</Text5>
              </Time>
            </Content2>
          </Avatar>
          <Actions>
            <Button1
              disableElevation={true}
              color="primary"
              variant="text"
              endIcon={<Icon>arrow_forward_ios_sharp</Icon>}
              
            >
              Read More
            </Button1>
          </Actions>
        </Card>
        <Card1 onClick={onCardContainer2Click}>
          <PlaceholderImageIcon alt="" src="/placeholder-image35@2x.png" />
          <Content1>
            <Heading1>Presentations</Heading1>
            <Cardcontent1>
              <Text6>Presentations</Text6>
              <Text7>
                Investor updates and presentations. A comprehensive overview of
                the company's performance, strategic initiatives and future
                outlook.
              </Text7>
            </Cardcontent1>
          </Content1>
          <Avatar>
            <PlaceholderImageIcon1 alt="" src="/placeholder-image36@2x.png" />
            <Content2>
              <Text4>Robert Johnson</Text4>
              <Time>
                <Text5>20 Feb 2022</Text5>
                <Div>•</Div>
                <Text5>6 min read</Text5>
              </Time>
            </Content2>
          </Avatar>
          <Actions>
            <Button1
              disableElevation={true}
              color="primary"
              variant="text"
              endIcon={<Icon>arrow_forward_ios_sharp</Icon>}
              
            >
              Read More
            </Button1>
          </Actions>
        </Card1>
      </Content3>
    </UpdatesRoot>
  );
};

export default Updates1;
