import * as React from "react";
import styled from "styled-components";

const Subheading = styled.div`
  position: relative;
  line-height: 150%;
  font-weight: 300;
  color: var(--primary-main);
  text-align: left;
`;
const Heading = styled.h2`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--heading-desktop-h2-size);
  line-height: 120%;
  text-transform: uppercase;
  font-weight: 400;
  font-family: var(--heading-desktop-h4);
`;
const WithALarge = styled.p`
  margin-block-start: 0;
  margin-block-end: 16px;
`;
const AnExperiencedTeam = styled.p`
  margin: 0;
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  font-weight: 300;
`;
const SectionTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  max-width: 50rem;
`;
const PlaceholderImage = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 22.206rem;
  flex-shrink: 0;
  object-fit: cover;
`;
const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  @media screen and (max-width: 420px) {
    flex: unset;
    align-self: stretch;
  }
`;
const PlaceholderImage1 = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 14.625rem;
  flex-shrink: 0;
  object-fit: cover;
`;
const PlaceholderImage2 = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 26rem;
  flex-shrink: 0;
  object-fit: cover;
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
`;
const GalleryRoot = styled.section`
  align-self: stretch;
  background-color: var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  gap: var(--gap-61xl);
  text-align: center;
  font-size: var(--text-medium-light-size);
  color: var(--border-primary);
  font-family: var(--text-regular-light);
  @media screen and (max-width: 420px) {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
`;

const Gallery = () => {
  return (
    <GalleryRoot>
      <SectionTitle>
        <Subheading>SUMMARY</Subheading>
        <Heading>The Mankayan Project</Heading>
        <Text1>
          <WithALarge>{`With a large undeveloped copper-gold deposit with a renewed mining license, the project is well-positioned to benefit from rising global copper demand and a supportive Philippine government. `}</WithALarge>
          <WithALarge>{`Despite its already impressive resource, the project holds potential for further growth with high-grade zones and strategic consolidation opportunities. `}</WithALarge>
          <AnExperiencedTeam>
            An experienced team is in place to advance the project through a
            professionally managed pre-feasibility study, unlocking significant
            value for stakeholders.
          </AnExperiencedTeam>
        </Text1>
      </SectionTitle>
      <Content>
        <Column>
          <PlaceholderImage alt="" src="/placeholder-image-1@2x.png" />
          <PlaceholderImage alt="" src="/placeholder-image-2@2x.png" />
          <PlaceholderImage alt="" src="/placeholder-image-31@2x.png" />
        </Column>
        <Column>
          <PlaceholderImage alt="" src="/placeholder-image-51@2x.png" />
          <PlaceholderImage alt="" src="/placeholder-image-32@2x.png" />
          <PlaceholderImage alt="" src="/placeholder-image-21@2x.png" />
        </Column>
        <Column>
          <PlaceholderImage1 alt="" src="/placeholder-image-41@2x.png" />
          <PlaceholderImage2 alt="" src="/placeholder-image-22@2x.png" />
          <PlaceholderImage2 alt="" src="/placeholder-image-8@2x.png" />
        </Column>
      </Content>
    </GalleryRoot>
  );
};

export default Gallery;
