import * as React from "react";
import styled from "styled-components";

const Jorc2012Mineral = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 140%;
  text-transform: uppercase;
`;
const CueqCutOff = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-regular-light-size);
  line-height: 150%;
  font-family: var(--text-regular-light);
`;
const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const CardHeader = styled.div`
  align-self: stretch;
  background-color: var(--primary-highlight);
  border-top: 1px solid var(--primary-main);
  border-right: 1px solid var(--primary-main);
  border-left: 1px solid var(--primary-main);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-5xl);
`;
const Name1 = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 800;
`;
const TableHeader = styled.div`
  align-self: stretch;
  border-bottom: 1px solid var(--primary-main);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-5xl);
  font-family: var(--text-regular-light);
`;
const FullName = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  font-weight: 500;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 1.5rem;
  flex-shrink: 0;
`;
const TableCell = styled.div`
  align-self: stretch;
  border-bottom: 1px solid var(--primary-main);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-9xl) var(--padding-5xl);
`;
const TableCell1 = styled.div`
  align-self: stretch;
  background-color: var(--color-tan-100);
  border-bottom: 1px solid var(--primary-main);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-9xl) var(--padding-5xl);
`;
const FullName1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  font-weight: 800;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 1.5rem;
  flex-shrink: 0;
`;
const TableCell2 = styled.div`
  align-self: stretch;
  background-color: var(--color-tan-100);
  border-bottom: 1px solid var(--primary-main);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-9xl) var(--padding-5xl);
  font-family: var(--text-regular-light);
`;
const TableColumn = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-family: var(--text-regular-normal);
`;
const Company = styled.div`
  position: relative;
  line-height: 150%;
  font-weight: 800;
`;
const TableHeader1 = styled.div`
  align-self: stretch;
  border-bottom: 1px solid var(--primary-main);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-5xl);
  text-align: left;
`;
const CompanyName = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 1.5rem;
  flex-shrink: 0;
`;
const TableCell3 = styled.div`
  align-self: stretch;
  border-bottom: 1px solid var(--primary-main);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xl) var(--padding-5xl);
`;
const TableCell4 = styled.div`
  align-self: stretch;
  background-color: var(--color-tan-100);
  border-bottom: 1px solid var(--primary-main);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xl) var(--padding-5xl);
`;
const TableColumn1 = styled.div`
  align-self: stretch;
  width: 7.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const Number1 = styled.div`
  position: relative;
  line-height: 150%;
  font-weight: 800;
  white-space: pre-wrap;
`;
const TableContainer = styled.div`
  align-self: stretch;
  border: 1px solid var(--primary-main);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--text-regular-light-size);
  font-family: var(--text-regular-light);
`;
const Table = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const CardHeader1 = styled.div`
  align-self: stretch;
  background-color: var(--primary-highlight);
  border-top: 1px solid var(--primary-main);
  border-right: 1px solid var(--primary-main);
  border-left: 1px solid var(--primary-main);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-5xl);
  font-size: var(--heading-desktop-h5-size);
  font-family: var(--heading-desktop-h4);
`;
const CompanyName1 = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 1.5rem;
  flex-shrink: 0;
`;
const TableCell5 = styled.div`
  align-self: stretch;
  width: 7.5rem;
  border-right: 1px solid var(--primary-main);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xl) var(--padding-5xl);
`;
const TableHeader2 = styled.div`
  align-self: stretch;
  flex: 1;
  border-bottom: 1px solid var(--primary-main);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-5xl);
`;
const TableHeader3 = styled.div`
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-5xl);
`;
const TableHeaderGroup = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;
const TableHeaderParent = styled.div`
  align-self: stretch;
  flex: 1;
  border-right: 1px solid var(--primary-main);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const TableHeaderContainer = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const TableColumn2 = styled.div`
  align-self: stretch;
  border: 1px solid var(--primary-main);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;
const Name2 = styled.div`
  position: relative;
  line-height: 150%;
`;
const TableHeader4 = styled.div`
  align-self: stretch;
  background-color: var(--color-tan-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl);
  color: var(--primary-main);
`;
const TableCell6 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const TableCell7 = styled.div`
  align-self: stretch;
  background-color: var(--color-tan-100);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const TableCell8 = styled.div`
  align-self: stretch;
  background-color: var(--color-tan-100);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-17xl) var(--padding-5xl);
`;
const TableColumn3 = styled.div`
  align-self: stretch;
  width: 7.5rem;
  border-right: 1px solid var(--primary-main);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const TableHeader5 = styled.div`
  align-self: stretch;
  background-color: var(--color-tan-100);
  border-bottom: 1px solid var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl);
`;
const TableCell9 = styled.div`
  align-self: stretch;
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const TableCell10 = styled.div`
  align-self: stretch;
  background-color: var(--color-tan-100);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const TableCell11 = styled.div`
  align-self: stretch;
  background-color: var(--color-tan-100);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-17xl) var(--padding-5xl);
`;
const TableHeader6 = styled.div`
  align-self: stretch;
  background-color: var(--color-mediumaquamarine);
  border-bottom: 1px solid var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl);
`;
const TableCell12 = styled.div`
  align-self: stretch;
  background-color: var(--confirm);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const TableCell13 = styled.div`
  align-self: stretch;
  background-color: var(--color-mediumaquamarine);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const TableCell14 = styled.div`
  align-self: stretch;
  background-color: var(--color-mediumaquamarine);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-17xl) var(--padding-5xl);
`;
const TableHeader7 = styled.div`
  align-self: stretch;
  background-color: var(--confirm);
  border-bottom: 1px solid var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl);
`;
const TableCell15 = styled.div`
  align-self: stretch;
  background-color: var(--confirm);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-17xl) var(--padding-5xl);
`;
const TableColumnParent = styled.div`
  align-self: stretch;
  flex: 1;
  border-right: 1px solid var(--primary-main);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const TableHeader8 = styled.div`
  align-self: stretch;
  background-color: var(--background1);
  border-bottom: 1px solid var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Div = styled.div`
  align-self: stretch;
  width: 4.45rem;
  position: relative;
  line-height: 150%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 0;
`;
const TableCell16 = styled.div`
  align-self: stretch;
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Div1 = styled.div`
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const TableCell17 = styled.div`
  align-self: stretch;
  background-color: var(--background1);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const TableCell18 = styled.div`
  align-self: stretch;
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const TableCell19 = styled.div`
  align-self: stretch;
  background-color: var(--color-antiquewhite);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const TableColumn4 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const TableHeader9 = styled.div`
  align-self: stretch;
  background-color: var(--background1);
  border-bottom: 1px solid var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const TableHeader10 = styled.div`
  align-self: stretch;
  background-color: var(--confirm);
  border-bottom: 1px solid var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const TableHeader11 = styled.div`
  align-self: stretch;
  background-color: var(--color-mediumseagreen-100);
  border-bottom: 1px solid var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const TableCell20 = styled.div`
  align-self: stretch;
  background-color: var(--color-mediumaquamarine);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const TableCell21 = styled.div`
  align-self: stretch;
  background-color: var(--confirm);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const TableCell22 = styled.div`
  align-self: stretch;
  background-color: var(--confirm);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const TableCell23 = styled.div`
  align-self: stretch;
  background-color: var(--error);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const TableHeader12 = styled.div`
  align-self: stretch;
  background-color: var(--yellow);
  border-bottom: 1px solid var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const TableCell24 = styled.div`
  align-self: stretch;
  background-color: var(--yellow);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const TableCell25 = styled.div`
  align-self: stretch;
  background-color: var(--color-lightcoral);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const TableCell26 = styled.div`
  align-self: stretch;
  background-color: var(--yellow);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const TableColumnGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const TableContainer1 = styled.div`
  align-self: stretch;
  border: 1px solid var(--primary-main);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Col1Row = styled.div`
  align-self: stretch;
  width: 7.5rem;
  background-color: var(--color-tan-100);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl);
  box-sizing: border-box;
  color: var(--primary-main);
`;
const Col2Row = styled.div`
  align-self: stretch;
  flex: 1;
  background-color: var(--color-tan-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl);
`;
const Col05Row = styled.div`
  align-self: stretch;
  flex: 1;
  background-color: var(--color-mediumaquamarine);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl);
`;
const Col06Row = styled.div`
  align-self: stretch;
  flex: 1;
  background-color: var(--confirm);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl);
`;
const Col01Row = styled.div`
  flex: 1;
  background-color: var(--background1);
  border-bottom: 1px solid var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Col02Row = styled.div`
  align-self: stretch;
  flex: 1;
  background-color: var(--background1);
  border-bottom: 1px solid var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const Col04Row = styled.div`
  flex: 1;
  background-color: var(--confirm);
  border-bottom: 1px solid var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Multirow = styled.div`
  width: 27.188rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Col04Row1 = styled.div`
  flex: 1;
  background-color: var(--color-mediumseagreen-100);
  border-bottom: 1px solid var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Col02Row1 = styled.div`
  flex: 1;
  background-color: var(--background1);
  border-bottom: 1px solid var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const Col05Row1 = styled.div`
  flex: 1;
  background-color: var(--yellow);
  border-bottom: 1px solid var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Multirow1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Row = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Col1Row1 = styled.div`
  align-self: stretch;
  width: 7.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  box-sizing: border-box;
`;
const Col2Row1 = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const Col05Row2 = styled.div`
  align-self: stretch;
  flex: 1;
  background-color: var(--confirm);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const Col01Row1 = styled.div`
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Col04Row2 = styled.div`
  background-color: var(--color-mediumaquamarine);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Col05Row3 = styled.div`
  background-color: var(--error);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Mulitrow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Col04Row3 = styled.div`
  background-color: var(--confirm);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Col05Row4 = styled.div`
  background-color: var(--yellow);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Col1Row2 = styled.div`
  align-self: stretch;
  width: 7.5rem;
  background-color: var(--color-tan-100);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  box-sizing: border-box;
`;
const Col2Row2 = styled.div`
  align-self: stretch;
  flex: 1;
  background-color: var(--color-tan-100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const Col05Row5 = styled.div`
  align-self: stretch;
  flex: 1;
  background-color: var(--color-mediumaquamarine);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const Div2 = styled.div`
  flex: 1;
  position: relative;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Col01Row2 = styled.div`
  width: 4.813rem;
  background-color: var(--background1);
  border-bottom: 1px solid var(--text-alternate);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Col02Row2 = styled.div`
  width: 4.813rem;
  background-color: var(--color-antiquewhite);
  border-bottom: 1px solid var(--text-alternate);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Col03Row = styled.div`
  width: 3.625rem;
  background-color: var(--color-antiquewhite);
  border-bottom: 1px solid var(--text-alternate);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Col04Row4 = styled.div`
  width: 5.688rem;
  background-color: var(--color-mediumaquamarine);
  border-bottom: 1px solid var(--text-alternate);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Col05Row6 = styled.div`
  width: 5.688rem;
  background-color: var(--color-lightcoral);
  border-bottom: 1px solid var(--text-alternate);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Col02Row3 = styled.div`
  width: 5.438rem;
  background-color: var(--color-antiquewhite);
  border-bottom: 1px solid var(--text-alternate);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Col04Row5 = styled.div`
  width: 5.688rem;
  background-color: var(--confirm);
  border-bottom: 1px solid var(--text-alternate);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Col01Row3 = styled.div`
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const Col04Row6 = styled.div`
  background-color: var(--confirm);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const Col05Row7 = styled.div`
  background-color: var(--yellow);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const FullName2 = styled.div`
  position: relative;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Col1Row3 = styled.div`
  align-self: stretch;
  width: 7.5rem;
  background-color: var(--color-tan-100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  box-sizing: border-box;
`;
const Col01Row4 = styled.div`
  background-color: var(--color-antiquewhite);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const Col05Row8 = styled.div`
  background-color: var(--color-lightcoral);
  border-bottom: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-5xl);
`;
const TableContainer2 = styled.div`
  align-self: stretch;
  border: 1px solid var(--primary-main);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Table1 = styled.div`
  align-self: stretch;
  // height: 54.625rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--text-regular-light-size);
  font-family: var(--text-regular-light);
`;
const PlaceholderImageIcon = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: auto;
  flex-shrink: 0;
  object-fit: cover;
`;
const LayoutRoot = styled.section`
  align-self: stretch;
  background-color: var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  gap: var(--gap-61xl);
  text-align: center;
  font-size: var(--heading-desktop-h5-size);
  color: var(--border-primary);
  font-family: var(--heading-desktop-h4);
`;

const TablesSection = () => {
  return (
    <LayoutRoot>
      <Table>
        <CardHeader>
          <Column>
            <Jorc2012Mineral>
              JORC 2012 MINERAL RESOURCE ESTIMATE
            </Jorc2012Mineral>
            <CueqCutOff>{`(0.25% CuEq cut-off) `}</CueqCutOff>
          </Column>
        </CardHeader>
        <TableContainer>
          <TableColumn>
            <TableHeader>
              <Name1>Resource Category</Name1>
            </TableHeader>
            <TableCell>
              <FullName>Measured</FullName>
            </TableCell>
            <TableCell1>
              <FullName>Indicated</FullName>
            </TableCell1>
            <TableCell>
              <FullName>Inferred</FullName>
            </TableCell>
            <TableCell2>
              <FullName1>TOTAL</FullName1>
            </TableCell2>
          </TableColumn>
          <TableColumn1>
            <TableHeader1>
              <Company>Mt</Company>
            </TableHeader1>
            <TableCell3>
              <CompanyName>-</CompanyName>
            </TableCell3>
            <TableCell4>
              <CompanyName>638</CompanyName>
            </TableCell4>
            <TableCell3>
              <CompanyName>155</CompanyName>
            </TableCell3>
            <TableCell4>
              <FullName1>793</FullName1>
            </TableCell4>
          </TableColumn1>
          <TableColumn1>
            <TableHeader1>
              <Company>CuEq (%)</Company>
            </TableHeader1>
            <TableCell3>
              <CompanyName>-</CompanyName>
            </TableCell3>
            <TableCell4>
              <CompanyName>0.68</CompanyName>
            </TableCell4>
            <TableCell3>
              <CompanyName>0.52</CompanyName>
            </TableCell3>
            <TableCell4>
              <FullName1>0.64</FullName1>
            </TableCell4>
          </TableColumn1>
          <TableColumn1>
            <TableHeader1>
              <Company>Cu (%)</Company>
            </TableHeader1>
            <TableCell3>
              <CompanyName>-</CompanyName>
            </TableCell3>
            <TableCell4>
              <CompanyName>0.37</CompanyName>
            </TableCell4>
            <TableCell3>
              <CompanyName>0.29</CompanyName>
            </TableCell3>
            <TableCell4>
              <FullName1>0.35</FullName1>
            </TableCell4>
          </TableColumn1>
          <TableColumn1>
            <TableHeader1>
              <Number1>Au (g/t)</Number1>
            </TableHeader1>
            <TableCell3>
              <CompanyName>-</CompanyName>
            </TableCell3>
            <TableCell4>
              <CompanyName>0.40</CompanyName>
            </TableCell4>
            <TableCell3>
              <CompanyName>0.30</CompanyName>
            </TableCell3>
            <TableCell4>
              <FullName1>0.38</FullName1>
            </TableCell4>
          </TableColumn1>
          <TableColumn1>
            <TableHeader1>
              <Company>Ag (g/t)</Company>
            </TableHeader1>
            <TableCell3>
              <CompanyName>-</CompanyName>
            </TableCell3>
            <TableCell4>
              <CompanyName>0.8</CompanyName>
            </TableCell4>
            <TableCell3>
              <CompanyName>0.5</CompanyName>
            </TableCell3>
            <TableCell4>
              <FullName1>0.8</FullName1>
            </TableCell4>
          </TableColumn1>
          <TableColumn1>
            <TableHeader1>
              <Company>Cu (Mt)</Company>
            </TableHeader1>
            <TableCell3>
              <CompanyName>-</CompanyName>
            </TableCell3>
            <TableCell4>
              <CompanyName>2.3</CompanyName>
            </TableCell4>
            <TableCell3>
              <CompanyName>0.5</CompanyName>
            </TableCell3>
            <TableCell4>
              <FullName1>2.8</FullName1>
            </TableCell4>
          </TableColumn1>
          <TableColumn1>
            <TableHeader1>
              <Company>Au (Moz)</Company>
            </TableHeader1>
            <TableCell3>
              <CompanyName>-</CompanyName>
            </TableCell3>
            <TableCell4>
              <CompanyName>8.2</CompanyName>
            </TableCell4>
            <TableCell3>
              <CompanyName>1.5</CompanyName>
            </TableCell3>
            <TableCell4>
              <FullName1>9.7</FullName1>
            </TableCell4>
          </TableColumn1>
          <TableColumn1>
            <TableHeader1>
              <Company>Ag (Moz)</Company>
            </TableHeader1>
            <TableCell3>
              <CompanyName>-</CompanyName>
            </TableCell3>
            <TableCell4>
              <CompanyName>18</CompanyName>
            </TableCell4>
            <TableCell3>
              <CompanyName>3</CompanyName>
            </TableCell3>
            <TableCell4>
              <FullName1>20</FullName1>
            </TableCell4>
          </TableColumn1>
        </TableContainer>
      </Table>
      <Table1>
        <CardHeader1>
          <Column>
            <Jorc2012Mineral>High Grade Intercepts</Jorc2012Mineral>
            <CueqCutOff>CuEq</CueqCutOff>
          </Column>
        </CardHeader1>
        <TableColumn2>
          <TableCell5>
            <CompanyName1>DHID</CompanyName1>
          </TableCell5>
          <TableHeaderParent>
            <TableHeader2>
              <Company>Significant Intercepts</Company>
            </TableHeader2>
            <TableHeaderGroup>
              <TableHeader3>
                <Company>From (m)</Company>
              </TableHeader3>
              <TableHeader3>
                <Company>To (m)</Company>
              </TableHeader3>
              <TableHeader3>
                <Company>Interval (m)</Company>
              </TableHeader3>
              <TableHeader3>
                <Company>Ave %Cu</Company>
              </TableHeader3>
              <TableHeader3>
                <Company>Ave ppm Au</Company>
              </TableHeader3>
            </TableHeaderGroup>
          </TableHeaderParent>
          <TableHeaderContainer>
            <TableHeader2>
              <Company>Higher Grade Intercepts</Company>
            </TableHeader2>
            <TableHeaderGroup>
              <TableHeader3>
                <Company>From (m)</Company>
              </TableHeader3>
              <TableHeader3>
                <Company>To (m)</Company>
              </TableHeader3>
              <TableHeader3>
                <Company>Interval (m)</Company>
              </TableHeader3>
              <TableHeader3>
                <Company>Ave %Cu</Company>
              </TableHeader3>
              <TableHeader3>
                <Company>Ave ppm Au</Company>
              </TableHeader3>
            </TableHeaderGroup>
          </TableHeaderContainer>
        </TableColumn2>
        <TableContainer1>
          <TableColumn3>
            <TableHeader4>
              <Name2>PFC-40</Name2>
            </TableHeader4>
            <TableCell6>
              <CompanyName>MMD-11</CompanyName>
            </TableCell6>
            <TableCell7>
              <CompanyName>PFC-44</CompanyName>
            </TableCell7>
            <TableCell6>
              <CompanyName>THM-22</CompanyName>
            </TableCell6>
            <TableCell8>
              <CompanyName>PFC-43</CompanyName>
            </TableCell8>
          </TableColumn3>
          <TableColumnParent>
            <TableHeaderContainer>
              <TableHeader5>
                <Name2>229.9</Name2>
              </TableHeader5>
              <TableCell9>
                <CompanyName>156.2</CompanyName>
              </TableCell9>
              <TableCell10>
                <CompanyName>247</CompanyName>
              </TableCell10>
              <TableCell9>
                <CompanyName>254</CompanyName>
              </TableCell9>
              <TableCell11>
                <CompanyName>308</CompanyName>
              </TableCell11>
            </TableHeaderContainer>
            <TableHeaderContainer>
              <TableHeader5>
                <Name2>1348.6</Name2>
              </TableHeader5>
              <TableCell9>
                <CompanyName>1067.5</CompanyName>
              </TableCell9>
              <TableCell10>
                <CompanyName>1219.30</CompanyName>
              </TableCell10>
              <TableCell9>
                <CompanyName>1008</CompanyName>
              </TableCell9>
              <TableCell11>
                <CompanyName>1055</CompanyName>
              </TableCell11>
            </TableHeaderContainer>
            <TableHeaderContainer>
              <TableHeader5>
                <Name2>1118.7</Name2>
              </TableHeader5>
              <TableCell9>
                <CompanyName>911.3</CompanyName>
              </TableCell9>
              <TableCell10>
                <CompanyName>972.30</CompanyName>
              </TableCell10>
              <TableCell9>
                <CompanyName>754</CompanyName>
              </TableCell9>
              <TableCell11>
                <CompanyName>747</CompanyName>
              </TableCell11>
            </TableHeaderContainer>
            <TableHeaderContainer>
              <TableHeader6>
                <Name2>0.422</Name2>
              </TableHeader6>
              <TableCell12>
                <CompanyName>0.51</CompanyName>
              </TableCell12>
              <TableCell13>
                <CompanyName>0.435</CompanyName>
              </TableCell13>
              <TableCell13>
                <CompanyName>0.491</CompanyName>
              </TableCell13>
              <TableCell14>
                <CompanyName>0.486</CompanyName>
              </TableCell14>
            </TableHeaderContainer>
            <TableHeaderContainer>
              <TableHeader7>
                <Name2>0.559</Name2>
              </TableHeader7>
              <TableCell12>
                <CompanyName>0.626</CompanyName>
              </TableCell12>
              <TableCell12>
                <CompanyName>0.584</CompanyName>
              </TableCell12>
              <TableCell12>
                <CompanyName>0.638</CompanyName>
              </TableCell12>
              <TableCell15>
                <CompanyName>0.593</CompanyName>
              </TableCell15>
            </TableHeaderContainer>
          </TableColumnParent>
          <TableColumnGroup>
            <TableColumn4>
              <TableHeader8>
                <Name2>558.3</Name2>
              </TableHeader8>
              <TableHeader8>
                <Name2>690.3</Name2>
              </TableHeader8>
              <TableHeader8>
                <Name2>883.3</Name2>
              </TableHeader8>
              <TableCell16>
                <Div>476.1</Div>
              </TableCell16>
              <TableCell16>
                <Div>814.7</Div>
              </TableCell16>
              <TableCell17>
                <Div1>487</Div1>
              </TableCell17>
              <TableCell17>
                <Div1>565</Div1>
              </TableCell17>
              <TableCell18>
                <Div>572</Div>
              </TableCell18>
              <TableCell19>
                <Div>395</Div>
              </TableCell19>
              <TableCell19>
                <Div>443</Div>
              </TableCell19>
              <TableCell19>
                <Div>560</Div>
              </TableCell19>
              <TableCell19>
                <Div>790.8</Div>
              </TableCell19>
            </TableColumn4>
            <TableColumn4>
              <TableHeader9>
                <Name2>582.3</Name2>
              </TableHeader9>
              <TableHeader9>
                <Name2>772.3</Name2>
              </TableHeader9>
              <TableHeader9>
                <Name2>1235.15</Name2>
              </TableHeader9>
              <TableCell16>
                <Div>545.3</Div>
              </TableCell16>
              <TableCell16>
                <Div>1067.5</Div>
              </TableCell16>
              <TableCell19>
                <Div>496</Div>
              </TableCell19>
              <TableCell19>
                <Div>1090</Div>
              </TableCell19>
              <TableCell18>
                <Div>1002</Div>
              </TableCell18>
              <TableCell19>
                <Div>422</Div>
              </TableCell19>
              <TableCell19>
                <Div>461</Div>
              </TableCell19>
              <TableCell19>
                <Div>625.8</Div>
              </TableCell19>
              <TableCell19>
                <Div>1034</Div>
              </TableCell19>
            </TableColumn4>
            <TableColumn4>
              <TableHeader8>
                <Name2>24</Name2>
              </TableHeader8>
              <TableHeader8>
                <Name2>82</Name2>
              </TableHeader8>
              <TableHeader8>
                <Name2>351.85</Name2>
              </TableHeader8>
              <TableCell16>
                <Div>69.2</Div>
              </TableCell16>
              <TableCell16>
                <Div>252.8</Div>
              </TableCell16>
              <TableCell19>
                <Div>9</Div>
              </TableCell19>
              <TableCell19>
                <Div>525</Div>
              </TableCell19>
              <TableCell18>
                <Div>430</Div>
              </TableCell18>
              <TableCell19>
                <Div>27</Div>
              </TableCell19>
              <TableCell19>
                <Div>18</Div>
              </TableCell19>
              <TableCell19>
                <Div>65.8</Div>
              </TableCell19>
              <TableCell19>
                <Div>243.2</Div>
              </TableCell19>
            </TableColumn4>
            <TableColumn4>
              <TableHeader10>
                <Name2>0.661</Name2>
              </TableHeader10>
              <TableHeader11>
                <Name2>0.486</Name2>
              </TableHeader11>
              <TableHeader10>
                <Name2>0.534</Name2>
              </TableHeader10>
              <TableCell20>
                <Div>0.463</Div>
              </TableCell20>
              <TableCell21>
                <Div>0.733</Div>
              </TableCell21>
              <TableCell20>
                <Div>0.493</Div>
              </TableCell20>
              <TableCell21>
                <Div>0.517</Div>
              </TableCell21>
              <TableCell22>
                <Div>0.581</Div>
              </TableCell22>
              <TableCell20>
                <Div>0.474</Div>
              </TableCell20>
              <TableCell23>
                <Div>1.048</Div>
              </TableCell23>
              <TableCell21>
                <Div>0.64</Div>
              </TableCell21>
              <TableCell21>
                <Div>0.589</Div>
              </TableCell21>
            </TableColumn4>
            <TableColumn4>
              <TableHeader10>
                <Name2>0.609</Name2>
              </TableHeader10>
              <TableHeader10>
                <Name2>0.622</Name2>
              </TableHeader10>
              <TableHeader12>
                <Name2>0.793</Name2>
              </TableHeader12>
              <TableCell23>
                <Div>1.09</Div>
              </TableCell23>
              <TableCell24>
                <Div>0.886</Div>
              </TableCell24>
              <TableCell25>
                <Div>1.777</Div>
              </TableCell25>
              <TableCell21>
                <Div>0.730</Div>
              </TableCell21>
              <TableCell26>
                <Div>0.8</Div>
              </TableCell26>
              <TableCell23>
                <Div>1.198</Div>
              </TableCell23>
              <TableCell25>
                <Div>1.995</Div>
              </TableCell25>
              <TableCell21>
                <Div>0.747</Div>
              </TableCell21>
              <TableCell21>
                <Div>0.604</Div>
              </TableCell21>
            </TableColumn4>
          </TableColumnGroup>
        </TableContainer1>
        {/* <TableContainer2>
          <Row>
            <Col1Row>
              <Name2>PFC-40</Name2>
            </Col1Row>
            <Col2Row>
              <Name2>229.9</Name2>
            </Col2Row>
            <Col2Row>
              <Name2>1348.6</Name2>
            </Col2Row>
            <Col2Row>
              <Name2>1118.7</Name2>
            </Col2Row>
            <Col05Row>
              <Name2>0.422</Name2>
            </Col05Row>
            <Col06Row>
              <Name2>0.559</Name2>
            </Col06Row>
            <Multirow1>
              <Multirow>
                <Col01Row>
                  <Name2>558.3</Name2>
                </Col01Row>
                <Col02Row>
                  <Name2>582.3</Name2>
                </Col02Row>
                <Col01Row>
                  <Name2>24</Name2>
                </Col01Row>
                <Col04Row>
                  <Name2>0.661</Name2>
                </Col04Row>
                <Col04Row>
                  <Name2>0.609</Name2>
                </Col04Row>
              </Multirow>
              <Multirow>
                <Col01Row>
                  <Name2>690.3</Name2>
                </Col01Row>
                <Col02Row>
                  <Name2>772.3</Name2>
                </Col02Row>
                <Col01Row>
                  <Name2>82</Name2>
                </Col01Row>
                <Col04Row1>
                  <Name2>0.486</Name2>
                </Col04Row1>
                <Col04Row>
                  <Name2>0.622</Name2>
                </Col04Row>
              </Multirow>
              <Multirow>
                <Col01Row>
                  <Name2>883.3</Name2>
                </Col01Row>
                <Col02Row1>
                  <Name2>1235.15</Name2>
                </Col02Row1>
                <Col01Row>
                  <Name2>351.85</Name2>
                </Col01Row>
                <Col04Row>
                  <Name2>0.534</Name2>
                </Col04Row>
                <Col05Row1>
                  <Name2>0.793</Name2>
                </Col05Row1>
              </Multirow>
            </Multirow1>
          </Row>
          <Row>
            <Col1Row1>
              <CompanyName>MMD-11</CompanyName>
            </Col1Row1>
            <Col2Row1>
              <CompanyName>156.2</CompanyName>
            </Col2Row1>
            <Col2Row1>
              <CompanyName>1067.5</CompanyName>
            </Col2Row1>
            <Col2Row1>
              <CompanyName>911.3</CompanyName>
            </Col2Row1>
            <Col05Row2>
              <CompanyName>0.51</CompanyName>
            </Col05Row2>
            <Col05Row2>
              <CompanyName>0.626</CompanyName>
            </Col05Row2>
            <Multirow1>
              <Mulitrow>
                <Col01Row1>
                  <Div>476.1</Div>
                </Col01Row1>
                <Col01Row1>
                  <Div>545.3</Div>
                </Col01Row1>
                <Col01Row1>
                  <Div>69.2</Div>
                </Col01Row1>
                <Col04Row2>
                  <Div>0.463</Div>
                </Col04Row2>
                <Col05Row3>
                  <Div>1.09</Div>
                </Col05Row3>
              </Mulitrow>
              <Mulitrow>
                <Col01Row1>
                  <Div>814.7</Div>
                </Col01Row1>
                <Col01Row1>
                  <Div>1067.5</Div>
                </Col01Row1>
                <Col01Row1>
                  <Div>252.8</Div>
                </Col01Row1>
                <Col04Row3>
                  <Div>0.733</Div>
                </Col04Row3>
                <Col05Row4>
                  <Div>0.886</Div>
                </Col05Row4>
              </Mulitrow>
            </Multirow1>
          </Row>
          <Row>
            <Col1Row2>
              <CompanyName>PFC-44</CompanyName>
            </Col1Row2>
            <Col2Row2>
              <CompanyName>247</CompanyName>
            </Col2Row2>
            <Col2Row2>
              <CompanyName>1219.30</CompanyName>
            </Col2Row2>
            <Col2Row2>
              <CompanyName>972.30</CompanyName>
            </Col2Row2>
            <Col05Row5>
              <CompanyName>0.435</CompanyName>
            </Col05Row5>
            <Col05Row2>
              <CompanyName>0.584</CompanyName>
            </Col05Row2>
            <Multirow1>
              <Mulitrow>
                <Col01Row2>
                  <Div2>487</Div2>
                </Col01Row2>
                <Col02Row2>
                  <Div2>496</Div2>
                </Col02Row2>
                <Col03Row>
                  <Div2>9</Div2>
                </Col03Row>
                <Col04Row4>
                  <Div2>0.493</Div2>
                </Col04Row4>
                <Col05Row6>
                  <Div2>1.777</Div2>
                </Col05Row6>
              </Mulitrow>
              <Mulitrow>
                <Col01Row2>
                  <Div2>565</Div2>
                </Col01Row2>
                <Col02Row3>
                  <Div2>1090</Div2>
                </Col02Row3>
                <Col02Row2>
                  <Div2>525</Div2>
                </Col02Row2>
                <Col04Row5>
                  <Div2>0.517</Div2>
                </Col04Row5>
                <Col04Row5>
                  <Div2>0.730</Div2>
                </Col04Row5>
              </Mulitrow>
            </Multirow1>
          </Row>
          <Row>
            <Col1Row1>
              <CompanyName>THM-22</CompanyName>
            </Col1Row1>
            <Col2Row1>
              <CompanyName>254</CompanyName>
            </Col2Row1>
            <Col2Row1>
              <CompanyName>1008</CompanyName>
            </Col2Row1>
            <Col2Row1>
              <CompanyName>754</CompanyName>
            </Col2Row1>
            <Col05Row5>
              <CompanyName>0.491</CompanyName>
            </Col05Row5>
            <Col05Row2>
              <CompanyName>0.638</CompanyName>
            </Col05Row2>
            <Mulitrow>
              <Col01Row3>
                <Div>572</Div>
              </Col01Row3>
              <Col01Row3>
                <Div>1002</Div>
              </Col01Row3>
              <Col01Row3>
                <Div>430</Div>
              </Col01Row3>
              <Col04Row6>
                <Div>0.581</Div>
              </Col04Row6>
              <Col05Row7>
                <Div>0.8</Div>
              </Col05Row7>
            </Mulitrow>
          </Row>
          <Row>
            <Col1Row3>
              <FullName2>PFC-43</FullName2>
            </Col1Row3>
            <Col2Row2>
              <FullName2>308</FullName2>
            </Col2Row2>
            <Col2Row2>
              <FullName2>1055</FullName2>
            </Col2Row2>
            <Col2Row2>
              <FullName2>747</FullName2>
            </Col2Row2>
            <Col05Row5>
              <FullName2>0.486</FullName2>
            </Col05Row5>
            <Col05Row2>
              <FullName2>0.593</FullName2>
            </Col05Row2>
            <Multirow1>
              <Mulitrow>
                <Col01Row4>
                  <Div>395</Div>
                </Col01Row4>
                <Col01Row4>
                  <Div>422</Div>
                </Col01Row4>
                <Col01Row4>
                  <Div>27</Div>
                </Col01Row4>
                <Col04Row2>
                  <Div>0.474</Div>
                </Col04Row2>
                <Col05Row3>
                  <Div>1.198</Div>
                </Col05Row3>
              </Mulitrow>
              <Mulitrow>
                <Col01Row4>
                  <Div>443</Div>
                </Col01Row4>
                <Col01Row4>
                  <Div>461</Div>
                </Col01Row4>
                <Col01Row4>
                  <Div>18</Div>
                </Col01Row4>
                <Col05Row3>
                  <Div>1.048</Div>
                </Col05Row3>
                <Col05Row8>
                  <Div>1.995</Div>
                </Col05Row8>
              </Mulitrow>
              <Mulitrow>
                <Col01Row4>
                  <Div>560</Div>
                </Col01Row4>
                <Col01Row4>
                  <Div>625.8</Div>
                </Col01Row4>
                <Col01Row4>
                  <Div>65.8</Div>
                </Col01Row4>
                <Col04Row3>
                  <Div>0.64</Div>
                </Col04Row3>
                <Col04Row3>
                  <Div>0.747</Div>
                </Col04Row3>
              </Mulitrow>
              <Mulitrow>
                <Col01Row4>
                  <Div>790.8</Div>
                </Col01Row4>
                <Col01Row4>
                  <Div>1034</Div>
                </Col01Row4>
                <Col01Row4>
                  <Div>243.2</Div>
                </Col01Row4>
                <Col04Row3>
                  <Div>0.589</Div>
                </Col04Row3>
                <Col04Row3>
                  <Div>0.604</Div>
                </Col04Row3>
              </Mulitrow>
            </Multirow1>
          </Row>
        </TableContainer2> */}
      </Table1>
      <PlaceholderImageIcon alt="" src="/placeholder-image1@2x.png" />
    </LayoutRoot>
  );
};

export default TablesSection;
