import * as React from "react";
import styled from "styled-components";

const Tagline = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const Heading = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 120%;
  text-transform: uppercase;
`;
const IdmsStrategyIs = styled.p`
  margin-block-start: 0;
  margin-block-end: 16px;
`;
const InConjunctionWith = styled.p`
  margin: 0;
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
  font-family: var(--text-regular-light);
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--heading-desktop-h2-size);
  font-family: var(--heading-desktop-h4);
`;
const SectionTitle = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Button1 = styled.div`
  position: relative;
  line-height: 150%;
`;
const IconChevronRight = styled.img`
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const StylelinkSmallfalseDark = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
`;
const Button2 = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
`;
const Button3 = styled.div`
  background-color: var(--primary-highlight);
  border: 1px solid var(--primary-main);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  font-size: var(--text-medium-light-size);
  color: var(--primary-main);
  font-family: var(--text-regular-light);
`;
const Actions = styled.div`
  width: 5.438rem;
  height: 2.5rem;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) 0rem 0rem;
  box-sizing: border-box;
  gap: var(--gap-5xl);
  color: var(--border-primary);
  font-family: var(--text-regular-normal);
`;
const Content1 = styled.div`
  width: 48rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-base);
`;
const Space = styled.div`
  align-self: stretch;
  height: 12.5rem;
  min-height: 11.25rem;
`;
const Circle = styled.div`
  width: 0.938rem;
  position: relative;
  border-radius: 50%;
  background-color: var(--primary-highlight);
  height: 0.938rem;
  display: none;
`;
const Divider = styled.div`
  flex: 1;
  position: relative;
  background-color: var(--primary-highlight);
  height: 0.188rem;
`;
const ProgressContainer = styled.div`
  align-self: stretch;
  height: 0.938rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
`;
const Content2 = styled.div`
  align-self: stretch;
  flex: 1;
  min-height: 11.25rem;
`;
const Spacer = styled.div`
  width: 2rem;
  height: 30.938rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
`;
const PlaceholderImageIcon = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
`;
const ImageContainer = styled.div`
  align-self: stretch;
  background-color: var(--primary-highlight);
  border-right: 10px solid var(--color-lightgray);
  border-left: 5px solid var(--color-lightgray);
  box-sizing: border-box;
  height: 12.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem var(--padding-4xs);
  min-height: 12.5rem;
`;
const Circle1 = styled.div`
  width: 0.938rem;
  position: relative;
  border-radius: 50%;
  background-color: var(--primary-highlight);
  height: 0.938rem;
`;
const ProgressContainer1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
`;
const Date1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 140%;
  text-transform: uppercase;
`;
const Date2 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-regular-light-size);
  line-height: 150%;
  font-family: var(--text-regular-light);
`;
const Content3 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem 0rem 0rem var(--padding-xs);
  gap: var(--gap-5xs);
`;
const Timelineitem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Date3 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 140%;
  text-transform: uppercase;
  display: inline-block;
  height: 1.25rem;
  flex-shrink: 0;
`;
const Timelineitem1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-base);
`;
const ImageContainer1 = styled.div`
  align-self: stretch;
  background-color: var(--primary-highlight);
  border-right: 10px solid var(--color-lightgray);
  border-left: 5px solid var(--color-lightgray);
  box-sizing: border-box;
  height: 12.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem var(--padding-4xs);
  min-height: 11.25rem;
`;
const Timelineitem2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
`;
const TimelineBottomRow = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const Date4 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 140%;
  text-transform: uppercase;
  display: inline-block;
  height: 2.813rem;
  flex-shrink: 0;
`;
const Content4 = styled.div`
  width: 10.931rem;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem 0rem 0rem var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-5xs);
`;
const Timelinegroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--heading-desktop-h5-size);
  color: var(--border-primary);
  font-family: var(--heading-desktop-h4);
`;
const TimelineRoot = styled.section`
  // width: 90rem;
  background-color: var(--color-gray-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  box-sizing: border-box;
  gap: var(--gap-61xl);
  text-align: left;
  font-size: var(--text-regular-light-size);
  color: var(--primary-main);
  font-family: var(--text-regular-light);
`;

const Timeline = () => {
  return (
    <TimelineRoot data-scroll-to="timeline21">
      <Content1>
        <SectionTitle>
          <Tagline>Discover</Tagline>
          <Content>
            <Heading>STRATEGY</Heading>
            <Text1>
              <IdmsStrategyIs>
                IDM’s strategy is to complete the PFS in a professional and
                timely manner with extensive consultation with the local
                community.
              </IdmsStrategyIs>
              <InConjunctionWith>
                In conjunction with this, given the camp of other copper-gold
                projects in the region, other potential acquisitions, will be
                assessed which could add positive synergies to Mankayan
              </InConjunctionWith>
            </Text1>
          </Content>
        </SectionTitle>
        <Actions>
          <StylelinkSmallfalseDark>
            <Button1>Sign Up</Button1>
            <IconChevronRight alt="" src="/icon--chevronright31.svg" />
          </StylelinkSmallfalseDark>
          <Button3>
            <Button2>Learn More</Button2>
          </Button3>
        </Actions>
      </Content1>
      <Timelinegroup>
        <TimelineBottomRow>
          <Spacer>
            <Space />
            <ProgressContainer>
              <Circle />
              <Divider />
            </ProgressContainer>
            <Content2 />
          </Spacer>
          <Timelineitem>
            <ImageContainer>
              <PlaceholderImageIcon alt="" src="/placeholder-image15@2x.png" />
            </ImageContainer>
            <ProgressContainer1>
              <Circle1 />
              <Divider />
            </ProgressContainer1>
            <Content3>
              <Date1>Started</Date1>
              <Date2>
                Completion of Pre-Feasibility Study and Submission of a DMPF
              </Date2>
            </Content3>
            <Content3>
              <Date1>SCOPE</Date1>
              <Date2>Geotechnical and Hydrogeological studies</Date2>
            </Content3>
          </Timelineitem>
          <Timelineitem1>
            <Content3>
              <Date3>{` `}</Date3>
              <Date1>STARTED</Date1>
              <Date2>Environmental Impact Assessment(EIA), EIS and ECC</Date2>
            </Content3>
            <Content3>
              <Date1>SCOPE</Date1>
              <Date2>Mining Design</Date2>
            </Content3>
            <ProgressContainer1>
              <Circle1 />
              <Divider />
            </ProgressContainer1>
            <ImageContainer>
              <PlaceholderImageIcon alt="" src="/placeholder-image16@2x.png" />
            </ImageContainer>
          </Timelineitem1>
          <Timelineitem>
            <ImageContainer>
              <PlaceholderImageIcon alt="" src="/placeholder-image17@2x.png" />
            </ImageContainer>
            <ProgressContainer1>
              <Circle1 />
              <Divider />
            </ProgressContainer1>
            <Content3>
              <Date1>Started</Date1>
              <Date2>Three Year Development and Utilization Plan</Date2>
            </Content3>
            <Content3>
              <Date1>SCOPE</Date1>
              <Date2>Metallurgical Test Work</Date2>
            </Content3>
          </Timelineitem>
          <Timelineitem2>
            <Content3>
              <Date1>STARTED</Date1>
              <Date2>
                Environmental Protection and Enhancement Plan (EPEP)
              </Date2>
            </Content3>
            <Content3>
              <Date1>SCOPE</Date1>
              <Date2>Concentrator and Associated Infrastructure Design</Date2>
            </Content3>
            <ProgressContainer1>
              <Circle1 />
              <Divider />
            </ProgressContainer1>
            <ImageContainer1>
              <PlaceholderImageIcon alt="" src="/placeholder-image18@2x.png" />
            </ImageContainer1>
          </Timelineitem2>
        </TimelineBottomRow>
        <TimelineBottomRow>
          <Spacer>
            <Space />
            <ProgressContainer>
              <Circle />
              <Divider />
            </ProgressContainer>
            <Content2 />
          </Spacer>
          <Timelineitem>
            <ImageContainer1>
              <PlaceholderImageIcon alt="" src="/placeholder-image19@2x.png" />
            </ImageContainer1>
            <ProgressContainer1>
              <Circle1 />
              <Divider />
            </ProgressContainer1>
            <Content3>
              <Date1>Started</Date1>
              <Date2>Proof of Approval / Endorsement of LGUs</Date2>
            </Content3>
            <Content3>
              <Date1>SCOPE</Date1>
              <Date2>Infrastructure and Tailings Storage Facility</Date2>
            </Content3>
          </Timelineitem>
          <Timelineitem>
            <Content3>
              <Date4>{` `}</Date4>
              <Date1>STARTED</Date1>
              <Date2>Social Development and Management Plan (SDMP)</Date2>
            </Content3>
            <Content3>
              <Date1>SCOPE</Date1>
              <Date2>Financial Modelling</Date2>
            </Content3>
            <ProgressContainer1>
              <Circle1 />
              <Divider />
            </ProgressContainer1>
            <ImageContainer1>
              <PlaceholderImageIcon alt="" src="/placeholder-image20@2x.png" />
            </ImageContainer1>
          </Timelineitem>
          <Timelineitem>
            <ImageContainer1>
              <PlaceholderImageIcon alt="" src="/placeholder-image21@2x.png" />
            </ImageContainer1>
            <ProgressContainer1>
              <Circle1 />
              <Divider />
            </ProgressContainer1>
            <Content3>
              <Date1>Started</Date1>
              <Date2>Indigenous Peoples Consent (FPIC)</Date2>
            </Content3>
            <Content4>
              <Date1>SCOPE</Date1>
              <Date2>Infrastructure and Tailings Storage Facility</Date2>
            </Content4>
          </Timelineitem>
        </TimelineBottomRow>
      </Timelinegroup>
    </TimelineRoot>
  );
};

export default Timeline;
