import * as React from "react";
import styled from "styled-components";

const Subheading = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const Heading = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 120%;
  text-transform: uppercase;
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  text-transform: uppercase;
  font-family: var(--text-regular-light);
  color: var(--text-alternate);
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--heading-desktop-h2-size);
  color: var(--primary-highlight);
  font-family: var(--heading-desktop-h4);
`;
const SectionTitle = styled.div`
  width: 48rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Subheading1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const Heading1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 130%;
  text-transform: uppercase;
`;
const ShowingTheDeposition = styled.p`
  margin-block-start: 0;
  margin-block-end: 8px;
`;
const Text2 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-regular-light-size);
  line-height: 150%;
  font-family: var(--text-regular-light);
  color: var(--border-primary);
`;
const Content1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--heading-desktop-h4-size);
  color: var(--primary-highlight);
  font-family: var(--heading-desktop-h4);
`;
const ContentTop = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const Button1 = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
`;
const IconChevronRight = styled.img`
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const Button2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
`;
const Actions = styled.div`
  width: 22rem;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--text-medium-light-size);
`;
const Content2 = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-13xl);
  gap: var(--gap-5xl);
`;
const PlaceholderImageIcon = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 26rem;
  flex-shrink: 0;
  object-fit: cover;
`;
const Image1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Card = styled.div`
  align-self: stretch;
  flex: 1;
  background-color: var(--color-gray-400);
  border: 1px solid var(--primary-highlight);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Actions1 = styled.div`
  width: 27.938rem;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--text-medium-light-size);
`;
const PlaceholderImageIcon1 = styled.img`
  width: 22.063rem;
  position: relative;
  height: 23.75rem;
  object-fit: cover;
`;
const Image2 = styled.div`
  height: 17.188rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Card1 = styled.div`
  align-self: stretch;
  background-color: var(--color-gray-400);
  border: 1px solid var(--primary-highlight);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const PlaceholderImageIcon2 = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
`;
const Image3 = styled.div`
  width: 22.063rem;
  height: 23.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Card2 = styled.div`
  align-self: stretch;
  background-color: var(--color-gray-400);
  border: 1px solid var(--primary-highlight);
  box-sizing: border-box;
  height: 17.313rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const OpportunityToAccess = styled.p`
  margin: 0;
`;
const Row = styled.div`
  width: 54rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
`;
const Row1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
`;
const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  color: var(--border-primary);
`;
const LayoutRoot = styled.section`
  // width: 90rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  box-sizing: border-box;
  gap: var(--gap-61xl);
  text-align: center;
  font-size: var(--text-regular-light-size);
  color: var(--text-alternate);
  font-family: var(--text-regular-light);
`;

const DepositSection = () => {
  return (
    <LayoutRoot>
      <SectionTitle>
        <Subheading>MANKAYAN</Subheading>
        <Content>
          <Heading>THE DEPOSIT</Heading>
          <Text1>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </Text1>
        </Content>
      </SectionTitle>
      <Container>
        <Row1>
          <Card>
            <Content2>
              <ContentTop>
                <Subheading1>Oblique view</Subheading1>
                <Content1>
                  <Heading1>{`>56,850m of Diamond Drilling`}</Heading1>
                  <Text2>
                    <ShowingTheDeposition>
                      Showing the deposition, location of PFS drill holes
                      together with grade shells for copper and gold.
                    </ShowingTheDeposition>
                  </Text2>
                </Content1>
              </ContentTop>
              <Actions>
                <Button2>
                  <Button1>Button</Button1>
                  <IconChevronRight alt="" src="/icon--chevronright2.svg" />
                </Button2>
              </Actions>
            </Content2>
            <Image1>
              <PlaceholderImageIcon alt="" src="/placeholder-image8@2x.png" />
            </Image1>
          </Card>
          <Row>
            <Card1>
              <Content2>
                <ContentTop>
                  <Subheading1>Tagline</Subheading1>
                  <Content1>
                    <Heading1>Drilling View</Heading1>
                    <Text2>{`The resource starts ~200m below surface to ~1,100m (900m x 300-400 intrusive complex) and is expected to be suitable to underground shaft and block caving. Higher grades zones within the global resource of >0.8%Cu and >1.0%Cu
`}</Text2>
                  </Content1>
                </ContentTop>
                <Actions1>
                  <Button2>
                    <Button1>Button</Button1>
                    <IconChevronRight alt="" src="/icon--chevronright2.svg" />
                  </Button2>
                </Actions1>
              </Content2>
              <Image2>
                <PlaceholderImageIcon1
                  alt=""
                  src="/placeholder-image9@2x.png"
                />
              </Image2>
            </Card1>
            <Card2>
              <Content2>
                <ContentTop>
                  <Subheading1>Tagline</Subheading1>
                  <Content1>
                    <Heading1>{`PFS Drill Holes `}</Heading1>
                    <Text2>
                      H2 2022 completed 2 diamond drill holes to 1,000m to test
                      metallurgy, geotech and hydrology – now with the labs, results due H2 2024.
                    </Text2>
                  </Content1>
                </ContentTop>
                <Actions1>
                  <Button2>
                    <Button1>Button</Button1>
                    <IconChevronRight alt="" src="/icon--chevronright2.svg" />
                  </Button2>
                </Actions1>
              </Content2>
              <Image3>
                <PlaceholderImageIcon2
                  alt=""
                  src="/placeholder-image10@2x.png"
                />
              </Image3>
            </Card2>
            <Card2>
              <Content2>
                <ContentTop>
                  <Subheading1>Tagline</Subheading1>
                  <Content1>
                    <Heading1>Potential Mining Scenario</Heading1>
                    <Text2>
                      <ShowingTheDeposition>
                        Previous scoping studies scenario envisaged both block
                        caving or sub-level caving mining methods
                      </ShowingTheDeposition>
                      <OpportunityToAccess>
                        Opportunity to access deposit by opening adit from
                        nearby valley
                      </OpportunityToAccess>
                    </Text2>
                  </Content1>
                </ContentTop>
                <Actions1>
                  <Button2>
                    <Button1>Button</Button1>
                    <IconChevronRight alt="" src="/icon--chevronright2.svg" />
                  </Button2>
                </Actions1>
              </Content2>
              <Image3>
                <PlaceholderImageIcon2
                  alt=""
                  src="/placeholder-image11@2x.png"
                />
              </Image3>
            </Card2>
          </Row>
        </Row1>
      </Container>
    </LayoutRoot>
  );
};

export default DepositSection;
