import * as React from "react";
import styled from "styled-components";

const Heading = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 120%;
  text-transform: uppercase;
  font-family: var(--heading-desktop-h4);
  color: var(--primary-highlight);
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
`;
const IconRelume = styled.img`
  width: 1rem;
  position: relative;
  height: 1rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const LoremIpsumDolor = styled.div`
  flex: 1;
  position: relative;
  line-height: 150%;
`;
const ListItem = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const List = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0rem;
  gap: var(--gap-base);
  font-size: var(--text-regular-light-size);
`;
const Content = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-13xl);
`;
const Column = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--heading-desktop-h3-size);
`;
const PlaceholderImage = styled.img`
  width: 26rem;
  position: relative;
  height: 14.625rem;
  object-fit: cover;
`;
const Text2 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
`;
const PlaceholderImage3Parent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
`;
const PlaceholderImage1 = styled.img`
  width: 26rem;
  position: relative;
  height: 26rem;
  object-fit: cover;
`;
const Column1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
`;
const Content1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
`;
const LayoutRoot = styled.section`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  text-align: left;
  font-size: var(--text-regular-light-size);
  color: var(--text-alternate);
  font-family: var(--text-regular-light);
`;

const CommunitySection = () => {
  return (
    <LayoutRoot>
      <Content1>
        <Column>
          <Content>
            <Heading>
              Responsible and Sustainable Mining Initiatives at the Mankayan
              Project
            </Heading>
            <Text1>
              We prioritize community engagement, environmental stewardship, and
              compliance with governmental regulations.
            </Text1>
            <List>
              <ListItem>
                <IconRelume alt="" src="/icon--relume3.svg" />
                <LoremIpsumDolor>Community Engagement</LoremIpsumDolor>
              </ListItem>
              <ListItem>
                <IconRelume alt="" src="/icon--relume3.svg" />
                <LoremIpsumDolor>Environmental Stewardship</LoremIpsumDolor>
              </ListItem>
              <ListItem>
                <IconRelume alt="" src="/icon--relume3.svg" />
                <LoremIpsumDolor>Governmental Compliance</LoremIpsumDolor>
              </ListItem>
            </List>
          </Content>
        </Column>
        <Column1>
          <PlaceholderImage3Parent>
            <PlaceholderImage alt="" src="/placeholder-image-3@2x.png" />
            <Text2>Provision of materials for school.</Text2>
          </PlaceholderImage3Parent>
          <PlaceholderImage3Parent>
            <PlaceholderImage alt="" src="/placeholder-image-4@2x.png" />
            <Text2>CDH-061 Planted with carrots and cabbages</Text2>
          </PlaceholderImage3Parent>
          <PlaceholderImage3Parent>
            <PlaceholderImage1 alt="" src="/placeholder-image-5@2x.png" />
            <Text2>Agriculture – social infrastructure</Text2>
          </PlaceholderImage3Parent>
        </Column1>
        <Column1>
          <PlaceholderImage3Parent>
            <PlaceholderImage1 alt="" src="/placeholder-image-6@2x.png" />
            <Text2>Agriculture – social infrastructure</Text2>
          </PlaceholderImage3Parent>
          <PlaceholderImage3Parent>
            <PlaceholderImage1 alt="" src="/placeholder-image-7@2x.png" />
            <Text2>Support to Youth Association</Text2>
          </PlaceholderImage3Parent>
        </Column1>
      </Content1>
    </LayoutRoot>
  );
};

export default CommunitySection;
