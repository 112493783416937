import * as React from "react";
import styled from "styled-components";

const MankayanSizeable = styled.p`
  margin: 0;
`;
const Heading = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 120%;
  text-transform: uppercase;
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
  font-family: var(--text-regular-light);
  color: var(--border-primary);
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const Content1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const PlaceholderImageIcon = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 26.188rem;
  flex-shrink: 0;
  object-fit: cover;
`;
const PlaceholderImageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-61xl);
`;
const LayoutRoot = styled.section`
  // width: 90rem;
  background-color: var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--heading-desktop-h3-size);
  color: var(--primary-highlight);
  font-family: var(--heading-desktop-h4);
`;

const DotchartSection = () => {
  return (
    <LayoutRoot>
      <Container>
        <Content1>
          <Content>
            <Heading>
              <MankayanSizeable>{`MANKAYAN – SIZEABLE AMONG `}</MankayanSizeable>
              <MankayanSizeable>GLOBAL PORPHYRYS</MankayanSizeable>
            </Heading>
            <Text1>
              The deposit has a respectable copper grade ranking among global
              porphyry Cu-Au deposits and is in the first quartile for gold
              grade. It is still open at depth and on the sides.
            </Text1>
          </Content>
        </Content1>
        <PlaceholderImageWrapper>
          <PlaceholderImageIcon alt="" src="/placeholder-image12@2x.png" />
        </PlaceholderImageWrapper>
      </Container>
    </LayoutRoot>
  );
};

export default DotchartSection;
